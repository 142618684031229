import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDsosList } from '../../../../../actions/dsosActions';
import { createLoadingSelector } from '../../../../../apis/selectors';

const actions = ['FETCH_DSOS_LIST'];
const loadingSelector = createLoadingSelector(actions);

export const useDsosList = () => {
  const dispatch = useDispatch();
  const { filters, total, data } = useSelector((state) => state.dsos.dsosList);
  const totalPages = Math.ceil(total / filters.limit);
  const isLoading = useSelector((state) => loadingSelector(state));

  useEffect(() => {
    dispatch(fetchDsosList(filters));
  }, [dispatch, filters]);

  return {
    data,
    total,
    totalPages,
    isLoading,
    limit: filters.limit,
  };
};
