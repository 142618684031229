import { Spinner } from 'react-bootstrap';
import { Colors } from '../../../../../themes/colors';

export const BorderedTable = ({ title, emptyText, data, onScroll, isLoading, Item }) => (
  <div
    style={{
      width: '50%',
      marginTop: 20,
    }}
  >
    <b style={{ fontSize: 16 }}>{title}</b>
    <div
      style={{
        marginTop: 4,
        paddingTop: 20,
        paddingBottom: 20,
        border: `2px solid ${Colors.neutral_600}`,
        height: 400,
        overflowY: data.length ? 'scroll' : 'hidden',
      }}
      onScroll={onScroll}
    >
      {!data.length && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <span>{emptyText}</span>
        </div>
      )}
      {data.map((item, index) => (
        <div
          key={item.id}
          style={{
            backgroundColor: index % 2 === 0 ? Colors.neutral_70 : 'transparent',
          }}
        >
          <Item item={item} />
        </div>
      ))}

      {isLoading && (
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 20 }}
        >
          <Spinner
            animation="border"
            role="status"
            variant="info"
            style={{
              height: 30,
              width: 30,
              marginLeft: 20,
              marginTop: 10,
            }}
          />
        </div>
      )}
    </div>
  </div>
);
