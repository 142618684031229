import { DSOS_ACTIONS } from '../actions/actionTypes';

const defaultState = {
  dsosList: {
    data: [],
    total: 0,
    filters: {
      page: 1,
      limit: 15,
    },
  },
  dsoGroupList: {
    dsoId: null,
    data: [],
    total: 0,
    isActiveRequest: false,
    isFinalPage: false,
    filters: {
      page: 1,
      limit: 15,
    },
  },
  dsoRegionList: {
    dsoId: null,
    data: [],
    total: 0,
    isActiveRequest: false,
    isFinalPage: false,
    filters: {
      page: 1,
      limit: 15,
    },
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
  case DSOS_ACTIONS.FETCH_DSOS_LIST_SUCCESS: {
    return {
      ...state,
      dsosList: {
        ...state.dsosList,
        total: action.payload.total,
        data: action.payload.data,
        filters: {
          ...state.dsosList.filters,
          page: action.payload.page,
        },
      },
    };
  }
  case DSOS_ACTIONS.ADD_DSO_SUCCESS: {
    const {
      total,
      data,
      filters: { limit },
    } = state.dsosList;

    return {
      ...state,
      dsosList: {
        ...state.dsosList,
        data: limit - data.length > 0 ? [...data, action.payload] : data,
        total: total + 1,
      },
    };
  }
  case DSOS_ACTIONS.FETCH_DSO_GROUP_LIST_REQUEST: {
    const isSameDsoId = state.dsoGroupList.dsoId === action.payload.dsoId;
    return {
      ...state,
      dsoGroupList: {
        ...state.dsoGroupList,
        dsoId: action.payload.dsoId,
        filters: {
          ...state.dsoGroupList.filters,
          page: isSameDsoId ? action.payload.page || 1 : 1,
        },
        data: isSameDsoId ? state.dsoGroupList.data : [],
        total: isSameDsoId ? state.dsoGroupList.total : 0,
        isActiveRequest: true,
        isFinalPage: isSameDsoId ? state.dsoGroupList.isFinalPage : false,
      },
    };
  }
  case DSOS_ACTIONS.FETCH_DSO_GROUP_LIST_SUCCESS: {
    return {
      ...state,
      dsoGroupList: {
        ...state.dsoGroupList,
        isActiveRequest: false,
        isFinalPage: action.payload.data.length < state.dsoGroupList.filters.limit,
        total: action.payload.total,
        data:
            state.dsoGroupList.filters.page === 1
              ? action.payload.data
              : [...state.dsoGroupList.data, ...action.payload.data],
      },
    };
  }
  case DSOS_ACTIONS.ADD_GROUP_TO_DSO_SUCCESS: {
    const { total, data } = state.dsoGroupList;

    return {
      ...state,
      dsoGroupList: {
        ...state.dsoGroupList,
        data: data.length === total ? [...data, action.payload] : data,
        total: total + 1,
      },
    };
  }
  case DSOS_ACTIONS.EDIT_DSO_GROUP_NAME_SUCCESS: {
    return {
      ...state,
      dsoGroupList: {
        ...state.dsoGroupList,
        data: state.dsoGroupList.data.map((group) => {
          if (group.id === action.payload.id) {
            return {
              ...group,
              name: action.payload.name,
            };
          }
          return group;
        }),
      },
    };
  }
  case DSOS_ACTIONS.FETCH_DSO_REGION_LIST_REQUEST: {
    const isSameDsoId = state.dsoRegionList.dsoId === action.payload.dsoId;
    return {
      ...state,
      dsoRegionList: {
        ...state.dsoRegionList,
        dsoId: action.payload.dsoId,
        filters: {
          ...state.dsoRegionList.filters,
          page: isSameDsoId ? action.payload.page || 1 : 1,
        },
        data: isSameDsoId ? state.dsoRegionList.data : [],
        total: isSameDsoId ? state.dsoRegionList.total : 0,
        isActiveRequest: true,
        isFinalPage: isSameDsoId ? state.dsoRegionList.isFinalPage : false,
      },
    };
  }
  case DSOS_ACTIONS.FETCH_DSO_REGION_LIST_SUCCESS: {
    return {
      ...state,
      dsoRegionList: {
        ...state.dsoRegionList,
        isActiveRequest: false,
        isFinalPage: action.payload.data.length < state.dsoRegionList.filters.limit,
        total: action.payload.total,
        data:
            state.dsoRegionList.filters.page === 1
              ? action.payload.data
              : [...state.dsoRegionList.data, ...action.payload.data],
      },
    };
  }
  case DSOS_ACTIONS.ADD_REGION_TO_DSO_SUCCESS: {
    const { total, data } = state.dsoRegionList;

    return {
      ...state,
      dsoRegionList: {
        ...state.dsoRegionList,
        data: data.length === total ? [...data, action.payload] : data,
        total: total + 1,
      },
    };
  }
  case DSOS_ACTIONS.EDIT_DSO_REGION_NAME_SUCCESS: {
    return {
      ...state,
      dsoRegionList: {
        ...state.dsoRegionList,
        data: state.dsoRegionList.data.map((region) => {
          if (region.id === action.payload.id) {
            return {
              ...region,
              name: action.payload.name,
            };
          }
          return region;
        }),
      },
    };
  }
  default:
    return state;
  }
};
