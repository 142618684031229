import { useDispatch, useSelector } from 'react-redux';
import { fetchDsosList } from '../../../../../actions/dsosActions';
import history from '../../../../../history';
import { dsosListColumn } from '../utils';

export const useDsosListTable = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.dsos.dsosList.filters);
  const getColumns = () => dsosListColumn();

  const handleTableChange = (page) => {
    dispatch(fetchDsosList({ ...filters, page }));
  };

  const onRowClicked = (row) => {
    history.push(`/dsos/${row.id}`, {
      id: row.id,
      name: row.name,
    });
  };

  return {
    filters,
    handleTableChange,
    columns: getColumns(),
    onRowClicked,
  };
};
