import tempMee from '../apis/tempMee';
import history from '../history';
import { removeFalsyValues } from '../utils';
import { isObjectEqual } from '../utils/Object';
import { DSOS_ACTIONS, SHOW_MESSAGE } from './actionTypes';
import { getAuthHeaders } from './authActions';

export const fetchDsosList = (filters) => async (dispatch, getState) => {
  try {
    const { data, filters: stateFilters } = getState().dsos.dsosList;
    const newFilterParams = { ...stateFilters, ...filters };

    if (data.length > 0 && isObjectEqual(stateFilters, newFilterParams)) {
      return;
    }

    dispatch({ type: DSOS_ACTIONS.FETCH_DSOS_LIST_REQUEST });
    const headers = await getAuthHeaders();
    const response = await tempMee.get('/office-service/admin/dsos', {
      params: {
        page: filters.page || stateFilters.page,
        limit: filters.limit || stateFilters.limit,
        ...removeFalsyValues(filters),
      },
      headers,
    });
    dispatch({
      type: DSOS_ACTIONS.FETCH_DSOS_LIST_SUCCESS,
      payload: { ...response.data, page: filters.page },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.FETCH_DSOS_LIST_FAILURE, payload: err });
  }
};

export const fetchDsoGroupList =
  (dsoId, filters = {}) =>
    async (dispatch, getState) => {
      dispatch({
        type: DSOS_ACTIONS.FETCH_DSO_GROUP_LIST_REQUEST,
        payload: { dsoId, page: filters.page },
      });
      try {
        const { page, limit } = getState().dsos.dsoGroupList.filters;
        const headers = await getAuthHeaders();
        const response = await tempMee.get(`/office-service/admin/dsos/${dsoId}/groups`, {
          params: {
            page: filters.page || page,
            limit: filters.limit || limit,
            ...removeFalsyValues(filters),
          },
          headers,
        });
        dispatch({
          type: DSOS_ACTIONS.FETCH_DSO_GROUP_LIST_SUCCESS,
          payload: response.data,
        });
      } catch (err) {
        dispatch({ type: DSOS_ACTIONS.FETCH_DSO_GROUP_LIST_FAILURE, payload: err });
      }
    };

export const addNewDso = (name) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.ADD_DSO_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(`/office-service/admin/dsos`, { name }, { headers });

    history.push(`/dsos/${response.data.id}`, {
      id: response.data.id,
      name: response.data.name,
    });

    dispatch({
      type: DSOS_ACTIONS.ADD_DSO_SUCCESS,
      payload: response.data,
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The DSO Group was created successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.ADD_DSO_FAILURE, payload: err });
  }
};

export const addNewGroupToDso = (dsoID, name) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.ADD_GROUP_TO_DSO_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(
      `/office-service/admin/dsos/${dsoID}/groups`,
      { name },
      {
        headers,
      },
    );
    dispatch({
      type: DSOS_ACTIONS.ADD_GROUP_TO_DSO_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The group was created successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.ADD_GROUP_TO_DSO_FAILURE, payload: err });
  }
};

export const editDsoGroupName = (dsoId, groupId, name) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.EDIT_DSO_GROUP_NAME_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `/office-service/admin/dsos/${dsoId}/groups/${groupId}`,
      { name },
      { headers },
    );
    dispatch({
      type: DSOS_ACTIONS.EDIT_DSO_GROUP_NAME_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The group name was edited successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.EDIT_DSO_GROUP_NAME_FAILURE, payload: err });
  }
};

export const fetchDsoRegionList =
  (dsoId, filters = {}) =>
    async (dispatch, getState) => {
      dispatch({
        type: DSOS_ACTIONS.FETCH_DSO_REGION_LIST_REQUEST,
        payload: { dsoId, page: filters.page },
      });
      try {
        const { page, limit } = getState().dsos.dsoRegionList.filters;
        const headers = await getAuthHeaders();
        const response = await tempMee.get(`/office-service/admin/dsos/${dsoId}/regions`, {
          params: {
            page: filters.page || page,
            limit: filters.limit || limit,
            ...removeFalsyValues(filters),
          },
          headers,
        });
        dispatch({
          type: DSOS_ACTIONS.FETCH_DSO_REGION_LIST_SUCCESS,
          payload: response.data,
        });
      } catch (err) {
        dispatch({ type: DSOS_ACTIONS.FETCH_DSO_REGION_LIST_FAILURE, payload: err });
      }
    };

export const addNewRegionToDso = (dsoID, name) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.ADD_REGION_TO_DSO_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(
      `/office-service/admin/dsos/${dsoID}/regions`,
      { name },
      {
        headers,
      },
    );
    dispatch({
      type: DSOS_ACTIONS.ADD_REGION_TO_DSO_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The region was created successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.ADD_REGION_TO_DSO_FAILURE, payload: err });
  }
};

export const editDsoRegionName = (dsoId, regionId, name) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.EDIT_DSO_REGION_NAME_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `/office-service/admin/dsos/${dsoId}/regions/${regionId}`,
      { name },
      { headers },
    );
    dispatch({
      type: DSOS_ACTIONS.EDIT_DSO_REGION_NAME_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The region name was edited successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.EDIT_DSO_REGION_NAME_FAILURE, payload: err });
  }
};
