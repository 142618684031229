import { style } from "../../../commonComponents/user-column";

export const dsosListColumn = () => [
  {
    id: 'name',
    selector: (row) => row.name,
    name: 'Name',
    style: style.tableText,
  },
  {
    id: 'offices',
    selector: () => '-',
    name: '# Offices',
    style: style.tableText,
  },
  {
    id: 'dso_manager',
    selector: () => '-',
    name: 'DSO Manager',
    style: style.tableText,
  },
  {
    id: 'dso_ram_manager',
    selector: () => '-',
    name: 'DSO RAM Manager',
    style: style.tableText,
  },
];