import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { EditPencilIcon } from '../../../../../images/icons';
import { Colors } from '../../../../../themes/colors';
import CustomPortal from '../../../../commonComponents/CustomPortal';
import { FormControl } from '../FormControl';

export const Item = ({ item, label, editAction }) => {
  const dispatch = useDispatch();
  const {
    state: { id: dsoId },
  } = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const [name, setName] = useState(item.name);
  const [showModal, setShowModal] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(editAction(dsoId, item.id, name));
    setShowModal(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 16,
        padding: '0 20px',
        gap: 12,
        height: 48,
        backgroundColor: isHovered ? Colors.neutral_200 : 'transparent',
        transition: 'background-color 0.3s ease',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span
        style={{
          flex: 1,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {item.name}
      </span>

      {isHovered && (
        <button
          type="button"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            appearance: 'none',
            border: 'none',
            backgroundColor: 'transparent',
          }}
          onClick={() => setShowModal(true)}
        >
          <EditPencilIcon size={16} />
          <span>Edit</span>
        </button>
      )}

      {showModal && (
        <CustomPortal
          title={`Edit ${label} Name`}
          textStyle={{ textAlign: 'center' }}
          cancelText="Close"
          submitText={`Edit ${label}`}
          submitPressed={handleSubmit}
          cancelPressed={() => {
            setShowModal(false);
            setName(item.name);
          }}
          rightButtonDisabled={!name || name === item.name}
        >
          <FormControl label={`Edit ${label} Name:`} value={name} onChange={handleNameChange} />
        </CustomPortal>
      )}
    </div>
  );
};
