import Spinner from 'react-bootstrap/Spinner';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { addNewRegionToDso, editDsoRegionName } from '../../../../../../actions/dsosActions';
import { BorderedTable } from '../../BorderedTable/BorderedTable';
import { Header } from '../../BorderedTable/Header';
import { Item } from '../../BorderedTable/Item';
import { useDsoRegionList } from './hooks';

export const RegionsTab = () => {
  const {
    state: { id: dsoId },
  } = useLocation();
  const { data, total, isLoading, handleScrollList, isInitialLoading } = useDsoRegionList(dsoId);

  return (
    <div
      className="card mt-3 ml-4 mr-4 mb-3"
      style={{
        display: 'flex',
        width: '80%',
        paddingBottom: 80,
      }}
    >
      <span
        style={{
          fontSize: 24,
          marginTop: 20,
          textAlign: 'center',
        }}
      >
        Regions
      </span>
      <div
        style={{
          margin: '0 80px',
        }}
      >
        {isInitialLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 400,
            }}
          >
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 40,
                width: 40,
              }}
            />
          </div>
        )}
        {!isInitialLoading && (
          <>
            <Header action={addNewRegionToDso} label="Region" total={total} />
            <BorderedTable
              title="Regions"
              emptyText="There are no regions"
              data={data}
              onScroll={handleScrollList}
              isLoading={isLoading}
              Item={({ item }) => (
                <Item editAction={editDsoRegionName} label="Region" item={item} />
              )}
            />
          </>
        )}
      </div>
    </div>
  );
};
