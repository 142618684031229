import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Styles from '../../../../themes/style.module.scss';
import { Table as TableComponent } from '../../../commonComponents/Table/Table';
import { Header } from './components/Header';
import { useDsosList } from './hooks/useDsosList';
import { useDsosListTable } from './hooks/useDsosListTable';

export const DsosList = () => {
  const { filters, handleTableChange, columns, onRowClicked } = useDsosListTable();
  const { total, data, totalPages, isLoading, limit } = useDsosList();

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        overflowY: 'auto',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 8,
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
          position: 'absolute',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <h3 className={Styles.h3} style={{ fontSize: '24px', textAlign: 'center' }}>
            DSO Groups
          </h3>
          {isLoading && (
            <div>
              <Spinner
                animation="border"
                role="status"
                variant="info"
                style={{
                  height: 30,
                  width: 30,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div style={{ width: '90%', paddingTop: 60 }}>
        <TableComponent
          filter={filters}
          columns={columns}
          initialPage={filters.page}
          data={data}
          noDataComponent="There are no DSO Groups"
          isLoading={isLoading}
          totalPages={totalPages}
          paginationPerPage={limit}
          paginationTotalRows={total}
          onPageChange={handleTableChange}
          onRowClicked={onRowClicked}
          CustomHeaderComponent={Header}
        />
      </div>
    </div>
  );
};
